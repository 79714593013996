import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';


export function* getMembershipDetailsSaga(action) {
    yield put(actions.getMembershipDetailsStart());

    // console.log('[getMembershipDetailsSaga] '+ JSON.stringify(action))

    // TODO -> REMOVE getStaffMembers because staff and personal trainers are in the uiGym entity
    try {
        const [contractsResponse, staffMemberResponse] = yield [axios.get('getContracts/' + action.locationId), axios.get('getStaffMembers/' + action.locationId)]
        // const [contractsResponse, staffMemberResponse] = yield axios.get('getContracts/' + action.locationId);
        yield put(actions.getContractsSuccess(contractsResponse.data));
        yield put(actions.getStaffMemberSuccess(staffMemberResponse.data));
        yield put(actions.getPersonalMembershipDetailsSuccess());
    } catch (error) {
        yield put(actions.getMembershipDetailsFail(error));
    }

}

export function* getContractsSaga(action) {
    yield put(actions.getContractsStart());

    try {
        const response = yield axios.get('getContracts/'+action.locationId);
        yield put(actions.getContractsSuccess(response.data))
    } catch (error) {
        yield put(actions.getContractsFail(error));
    }
}

export function* getTrainersSaga(action) {

    try {
        const personalTrainersResponse = yield axios.get('getPersonalTrainers/' + action.locationId);
        // yield put(actions.getBunkerTrainersSuccess(personalTrainersResponse.data))
        yield put(actions.getPersonalTrainersSuccess(personalTrainersResponse.data));
        // yield put(actions.getPersonalMembershipDetailsSuccess());
    } catch (error) {
        // yield put(actions.getBunkerTrainersFail(error));
        yield put(actions.getMembershipDetailsFail(error));
    }
}