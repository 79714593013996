import * as constants from './constants';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

// Danny's recomendation
// export const updateObject = (object = {}, properties) => {
//     const newObject = {...object}

//     properties.forEach(({ key, value }) => {
//         const foundKey = newObject.find(obj => key === obj.key)
//         const foundKeyIndex = newObject.indexOf(foundKey)

//         newObject[foundKeyIndex].value = value
//     })

//     return newObject
// }

export const cleanCamelCase = (input) => {
    if (input == null) { return; }
    const split = input.split(/(?=[A-Z])/);
    let output = '';
    split.forEach(function (word, index) {
        output += capitalise(word) + ' '
    })
    return output;
}

export const capitalise = (input) => {
    if (input == null) { return; }
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export const cleanCommaSeperatedCamelCase = (input) => {

    if (input == null) { return; }

    if (input === '[]') {
        return '';
    }

    const split = input.split(',');
    let output = '';
    split.forEach(function (word, index) {
        output += cleanCamelCase(word).slice(0, -1) + ','
    })
    return output.split(',').join(', ').slice(0, -2);
}

export const isValidUIStaff = (mboIdAndSiteId) => {

    const split = mboIdAndSiteId.split(constants.MBO_ID_AND_SITE_ID_DELINEATOR);
    const mboId = parseInt(split[0]);
    return mboId > 0;
}

export const isSydneyUIStaff = (mboIdAndSiteId) => {
    const split = mboIdAndSiteId.split(constants.MBO_ID_AND_SITE_ID_DELINEATOR);
    const siteId = split[1]
    return siteId.includes(constants.SYDNEY_SITE_ID)
}

export const getData = (formData) => {
    const data = {};
    for (let formElementIdentifier in formData) {
        data[formElementIdentifier] = formData[formElementIdentifier].value;
    }
    return data;
}

export const getIntroPtContracts = () => {
    // 492: 'Results PT Pack'
    // 491: 'Kick Start PT Pack'    
    return [491, 492];        
}