import React from 'react';

import classes from './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import * as constants from '../../../shared/constants';

const navigationItems = (props) => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/" exact>Home</NavigationItem>

        {props.isAuthenticated
            ? localStorage.getItem('permission') === constants.PERMISSION_LEVEL_LOCATION
                ? <NavigationItem link="/enrolment">Enrolment Form</NavigationItem>
                : localStorage.getItem('permission') === constants.PERMISSION_LEVEL_TESTER
                    ? <NavigationItem link="/enrolment">Enrolment Form</NavigationItem>
                    : null
            : null}

        {props.isAuthenticated
            ? <NavigationItem link="/viewEnrolments">Submissions</NavigationItem>
            : null}

        {props.isAuthenticated
            ? localStorage.getItem('permission') === constants.PERMISSION_LEVEL_GOD
                ? <NavigationItem link="/manualSubmissions">Manual Submissions</NavigationItem>
                : localStorage.getItem('permission') === constants.PERMISSION_LEVEL_ADMINISTRATOR
                    ? <NavigationItem link="/manualSubmissions">Manual Submissions</NavigationItem>
                    : null
            : null}
        
        {props.isAuthenticated && [constants.PERMISSION_LEVEL_GOD, constants.PERMISSION_LEVEL_MANAGER, constants.PERMISSION_LEVEL_DEPARTMENT_HEAD_COACH, constants.PERMISSION_LEVEL_HEAD_COACH].includes(localStorage.getItem('permission'))
            ? <NavigationItem link="/settings/updateGym">Update Gym</NavigationItem>
            : null}
            
        {props.isAuthenticated && [constants.PERMISSION_LEVEL_GOD, constants.PERMISSION_LEVEL_MANAGER, constants.PERMISSION_LEVEL_DEPARTMENT_HEAD_COACH, constants.PERMISSION_LEVEL_HEAD_COACH].includes(localStorage.getItem('permission'))
            ? <NavigationItem link="/settings/updateStaff">Update Staff</NavigationItem>
            : null}

        {!props.isAuthenticated
            ? <NavigationItem link="/auth">Login</NavigationItem>
            : <NavigationItem link="/logout">Logout</NavigationItem>}

    </ul>
);

export default navigationItems;