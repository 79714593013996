
export const handleDayUpdate = (updateValue) => {

  // console.log('handleDayUpdate ' + updateValue)
  const splitValue = updateValue.split('-');
  // console.log('splitValue: ' + splitValue)
  // console.log('Day Return Value: D|' + splitValue[2])
  return 'D|' + splitValue[2];

}

export const handleMonthUpdate = (updateValue) => {

  // console.log('handleMonthUpdate ' + updateValue)
  const splitValue = updateValue.split('-');
  // console.log('splitValue: ' + splitValue)
  // console.log('Month Return Value: M|' + splitValue[1])
  return 'M|' + splitValue[1];
}

export const handleYearUpdate = (updateValue) => {

  // console.log('handleYearUpdate ' + updateValue)
  const splitValue = updateValue.split('-');
  // console.log('splitValue: ' + splitValue)
  // console.log('Year Return Value: Y|' + splitValue[0])
  return 'Y|' + splitValue[0];
}

export const handleDateUpdate = (updateValue, currentValue) => {
  // console.log('[handleDateUpdate] ' + updateValue + ' ' + currentValue)

  let value = currentValue.split('-');
  let day = value[2];
  let month = value[1];
  let year = value[0];

  const splitValue = updateValue.split('|');
  const switchValue = splitValue[0];
  const dateValue = splitValue[1];

  switch (switchValue) {
    case ('Y'):
      year = dateValue;
      break;
    case ('M'):
      month = dateValue;
      break;
    case ('D'):
      day = dateValue;
      break;
    default:
      return currentValue;
  }

  const returnValue = year + '-' + month + '-' + day;
  // console.log('[handleDateUpdate] Return Value ' + returnValue)
  return returnValue;
}



export const GET_MONTHS =
  [
    { value: 'M|00', displayValue: 'Month' },
    { value: 'M|01', displayValue: 'January' },
    { value: 'M|02', displayValue: 'February' },
    { value: 'M|03', displayValue: 'March' },
    { value: 'M|04', displayValue: 'April' },
    { value: 'M|05', displayValue: 'May' },
    { value: 'M|06', displayValue: 'June' },
    { value: 'M|07', displayValue: 'July' },
    { value: 'M|08', displayValue: 'August' },
    { value: 'M|09', displayValue: 'September' },
    { value: 'M|10', displayValue: 'October' },
    { value: 'M|11', displayValue: 'November' },
    { value: 'M|12', displayValue: 'December' }
  ]

export const GET_YEARS = (from, to) => {
  let arr = [{ value: 'Y|00', displayValue: 'Year' },];
  for (let i = from; i >= to; i--) {
    // i = i<10 ? '0'+i : i;
    arr.push(
      { value: 'Y|' + i, displayValue: i.toString() },
    );
  }
  return arr;
}

export const GET_DOB_YEARS = () => {
  const d = new Date();
  const from = d.getFullYear() - 10;
  const to = d.getFullYear() - 90;
  let arr = [{ value: 'Y|00', displayValue: 'Year' },];
  for (let i = from; i >= to; i--) {
    // i = i<10 ? '0'+i : i;
    arr.push(
      { value: 'Y|' + i, displayValue: i.toString() },
    );
  }
  return arr;
}

export const GET_DAYS = () => {
  // let arr = [];
  let arr = [{ disabled: 'disabled', value: 'D|00', displayValue: 'Day' },];
  for (let i = 1; i <= 31; i++) {
    let j = i < 10 ? '0' + i : i;
    arr.push(
      { value: 'D|' + j, displayValue: i.toString() },
    );
  }
  return arr;
}

export const MAKE_DATE_PRETTY = (date) => {
  // console.log("[MAKE_DATE_PRETTY] input date: "+date);
  // date = date.replace(' ', 'T');  
  if (date === null) {
    return date;
  }

  date = date.split(' ')[0];
  if (!date) return date;
  // console.log("[MAKE_DATE_PRETTY] replace ÷date: "+date);
  var d = new Date(date);
  // console.log("[MAKE_DATE_PRETTY] date: "+d);

  const day_of_week = d.getDay();
  const month = (d.getMonth() + 1);
  const day = d.getDate();
  const prettyDate = WEEK_DAY_NAME[day_of_week] + ', ' + day + ' ' + CALENDAR_MONTH_NAME[month];

  // console.log("[MAKE_DATE_PRETTY] prettyDate: "+prettyDate);

  return prettyDate;
}

export const MAKE_FULL_DATE_PRETTY = (date) => {
  // console.log("[MAKE_DATE_PRETTY] date: "+date);
  // date = date.replace(' ', 'T');
  // var d = new Date(date);
  if (date === null) {
    return date;
  }

  var split = date.split(' ');

  if (!split) return date;

  var d = new Date(split[0]);
  // console.log("[MAKE_DATE_PRETTY] date: "+d);

  const day_of_week = d.getDay();
  const month = (d.getMonth() + 1);
  const day = d.getDate();
  const year = d.getFullYear();
  const prettyDate = WEEK_DAY_NAME[day_of_week] + ', ' + day + ' ' + CALENDAR_MONTH_NAME[month] + ' ' + year;
  return prettyDate;
}

export const GET_NEXT_THURSDAY_DATE_PRETTY = (startDate) => {
  startDate = startDate.replace(' ', 'T');
  var d = new Date(startDate);
  d.setDate(d.getDate() + (4 + 7 - d.getDay()) % 7);
  const day_of_week = d.getDay();
  const month = (d.getMonth() + 1);
  const day = d.getDate();
  const date = WEEK_DAY_NAME[day_of_week] + ', ' + day + ' ' + CALENDAR_MONTH_NAME[month];
  return date;
}

export const GET_NEXT_THURSDAY_DATE = (startDate) => {

  var d = new Date(startDate);
  d.setDate(d.getDate() + (4 + 7 - d.getDay()) % 7);
  const year = d.getFullYear();
  const month = (d.getMonth() + 1);
  const __month__ = month < 10 ? '0' + month : month;
  const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
  const date = year + '-' + __month__ + '-' + day;
  return date;
}

export const GET_PLUS_2_MONDAY_DATE_PRETTY = (startDate) => {
  startDate = startDate.replace(' ', 'T');
  var d = new Date(startDate);
  d.setDate(d.getDate() + (1 + 14 - d.getDay()) % 14);
  // console.log(d);
  const day_of_week = d.getDay();
  const month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
  const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
  const date = WEEK_DAY_NAME[day_of_week] + ', ' + day + ' ' + CALENDAR_MONTH_NAME[month];
  return date;
}

export const GET_PLUS_2_MONDAY_DATE = (startDate) => {

  var d = new Date(startDate);
  const addDays = (1 + 14 - d.getDay()) % 14 === 0 ? 14 : (1 + 14 - d.getDay()) % 14
  d.setDate(d.getDate() + addDays);

  // console.log("(1 + 14 - d.getDay()) % 14) = "+(1 + 14 - d.getDay()) % 14);
  // console.log("addDays = "+addDays);

  const year = d.getFullYear();
  const month = (d.getMonth() + 1);
  const __month__ = month < 10 ? '0' + month : month;
  const day = d.getDate();
  const __day__ = d.getDate() < 10 ? '0' + day : day;
  const date = year + '-' + __month__ + '-' + __day__;
  // console.log('PLUS 2 MONDAY DATE: ' + date)
  return date;
}

export const GET_INTRO_COACHING_START_DATE = (startDate, numberSessionsPerWeek, introPt) => {

  let introPtSessionCount = 0;
  let sessionCount = 0;

  if (introPt === 'six') {
    introPtSessionCount = 6;
  } else if (introPt === 'ten') {
    introPtSessionCount = 10;
  }

  if (numberSessionsPerWeek === '4wk') {
    sessionCount = 4;
  } else if (numberSessionsPerWeek === '3wk') {
    sessionCount = 3;
  } else if (numberSessionsPerWeek === '2wk') {
    sessionCount = 2;
  } else if (numberSessionsPerWeek === '1wk') {
    sessionCount = 1;
  } else if (numberSessionsPerWeek === '1fn') {
    sessionCount = 0.5;
  }

  const addWeeks = introPtSessionCount / sessionCount
  var d = new Date(startDate);
  d.setDate(d.getDate() + addWeeks * 7)
  const year = d.getFullYear();
  const month = (d.getMonth() + 1);
  const __month__ = month < 10 ? '0' + month : month;
  const day = d.getDate();
  const __day__ = d.getDate() < 10 ? '0' + day : day;
  const date = year + '-' + __month__ + '-' + __day__;
  // console.log('GET_INTRO_COACHING_START_DATE: ' + date)
  return date;
}

export const UNDER18 = (dob) => {
  const d = new Date(dob);
  const ageDiffMs = Date.now() - d.getTime();
  const ageDate = new Date(ageDiffMs);
  // console.log(ageDate.getUTCFullYear() - 1970)
  return ageDate.getUTCFullYear() - 1970 < 18;
}


export const GET_DATE_NOW = () => {
  const d = new Date();
  const year = d.getFullYear();
  let month = (d.getMonth() + 1);
  month = month < 10 ? '0' + month : month;
  let day = d.getDate();
  day = day < 10 ? '0' + day : day;
  const date = year + '-' + month + '-' + day

  // console.log("[GET_DATE_NOW] "+year + '-' + month + '-' + day)

  return date;
}

export const GET_TIMESTAMP_NOW = () => {
  const d = new Date();
  const year = d.getFullYear();
  let month = (d.getMonth() + 1);
  month = month < 10 ? '0' + month : month;
  let day = d.getDate();
  day = day < 10 ? '0' + day : day;
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();
  // const time = d.toTimeString();
  const date = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  return date;
}

export const GET_DATE_PLUS_N_DAYS = (from_date, n_days) => {
  let d = new Date(from_date);
  d.setDate(d.getDate() + n_days);
  const year = d.getFullYear();
  let month = (d.getMonth() + 1);
  month = month < 10 ? '0' + month : month;
  let day = d.getDate();
  day = day < 10 ? '0' + day : day;
  const date = year + '-' + month + '-' + day
  // console.log('GET_DATE_PLUS_N_DAYS: + from_date '+from_date +' | n_days ' + n_days + ' => ' + date)
  return date;
}

export const GET_DATE_NOW_PLUS_N_DAYS = (n_days) => {
  let d = new Date();

  d.setDate(d.getDate() + n_days);

  const year = d.getFullYear();
  let month = (d.getMonth() + 1);
  month = month < 10 ? '0' + month : month;
  let day = d.getDate();
  day = day < 10 ? '0' + day : day;
  const date = year + '-' + month + '-' + day

  // console.log('GET_DATE_NOW_PLUS_N_DAYS: + ' + n_days + ' => ' + date)

  return date;
}

export const GET_DATE_PLUS_N_YEAR = (startDate, n_years) => {
  const d = new Date(startDate);
  const year = d.getFullYear();
  const month = (d.getMonth() + 1);
  const day = (d.getDate() + n_years);
  const date = year + '-' + month + '-' + day;
  return date;
}

export const GET_DATE_PLUS_N_YEAR_PRETTY = (startDate, n_years) => {
  const d = new Date(startDate);
  const year = (d.getFullYear() + n_years);
  const month = (d.getMonth() + 1);
  const day = d.getDate();
  const date = day + ' ' + CALENDAR_MONTH_NAME[month] + ' ' + year;
  return date;
}


export const GET_DAYS_DEFAULT_NEXT_THURS = () => {
  const d = new Date();
  d.setDate(d.getDate() + (4 + 7 - d.getDay()) % 7);
  // const year = (d.getFullYear() + n_years);
  // const month = (d.getMonth() + 1);
  let day = d.getDate();
  const __day__ = day < 10 ? '0' + day : day;

  let arr = [{ value: 'D|' + __day__, displayValue: day.toString() },];
  for (let i = 1; i <= 31; i++) {
    if (i !== day) {
      let j = i < 10 ? '0' + i : i;
      arr.push(
        { value: 'D|' + j, displayValue: i.toString() },
      );
    }
  }
  // console.log('GET_DAYS_DEFAULT_NEXT_THURS ' + arr)
  return arr;
}

export const GET_MONTHS_DEFAULT_NEXT_THURS = () => {
  let returnMonths = [];
  const d = new Date();
  d.setDate(d.getDate() + (4 + 7 - d.getDay()) % 7);
  let month = (d.getMonth() + 1);

  returnMonths.push(GET_MONTHS[month]);

  // GET_MONTHS.forEach((m, index) => {
  //   if (m.value !== returnMonths[0].value && m.value !== GET_MONTHS[0].value) {
  //     returnMonths.push(m);
  //   }
  // })

  GET_MONTHS.forEach((m = {}, index) => {
    const monthItem = returnMonths[0] || {}
    const getMonthsItem = GET_MONTHS[0] || {}

    if (m.value !== monthItem.value && m.value !== getMonthsItem.value) {
      returnMonths.push(m);
    }
  })

  // console.log('GET_MONTHS_DEFAULT_NEXT_THURS ' + returnMonths);
  return returnMonths;
}

export const GET_DAYS_DEFAULT_PLUS_2_MONDAY = (startDate) => {
  const d = new Date(startDate);
  const addDays = (1 + 14 - d.getDay()) % 14 === 0 ? 14 : (1 + 14 - d.getDay()) % 14
  d.setDate(d.getDate() + addDays);
  // const year = (d.getFullYear() + n_years);
  // const month = (d.getMonth() + 1);
  let day = d.getDate();
  const __day__ = day < 10 ? '0' + day : day;

  let arr = [{ value: 'D|' + __day__, displayValue: day.toString() },];
  for (let i = 1; i <= 31; i++) {
    if (i !== day) {
      let j = i < 10 ? '0' + i : i;
      arr.push(
        { value: 'D|' + j, displayValue: i.toString() },
      );
    }
  }
  // console.log('GET_DAYS_DEFAULT_NEXT_THURS ' + JSON.stringify(arr))
  return arr;
}

export const GET_DAYS_DEFAULT_INTRO_PT = (startDate, numberSessionsPerWeek, introPt) => {
  let introPtSessionCount = 0;
  let sessionCount = 0;

  if (introPt === 'six') {
    introPtSessionCount = 6;
  } else if (introPt === 'ten') {
    introPtSessionCount = 10;
  }

  if (numberSessionsPerWeek === '4wk') {
    sessionCount = 4;
  } else if (numberSessionsPerWeek === '3wk') {
    sessionCount = 3;
  } else if (numberSessionsPerWeek === '2wk') {
    sessionCount = 2;
  } else if (numberSessionsPerWeek === '1wk') {
    sessionCount = 1;
  } else if (numberSessionsPerWeek === '1fn') {
    sessionCount = 0.5;
  }

  const addWeeks = introPtSessionCount / sessionCount
  var d = new Date(startDate);
  d.setDate(d.getDate() + addWeeks * 7)
  let day = d.getDate();
  const __day__ = day < 10 ? '0' + day : day;

  let arr = [{ value: 'D|' + __day__, displayValue: day.toString() },];
  for (let i = 1; i <= 31; i++) {
    if (i !== day) {
      let j = i < 10 ? '0' + i : i;
      arr.push(
        { value: 'D|' + j, displayValue: i.toString() },
      );
    }
  }
  // console.log('GET_DAYS_DEFAULT_NEXT_THURS ' + JSON.stringify(arr))
  return arr;
}

export const GET_MONTHS_DEFAULT_PLUS_2_MONDAY = (startDate) => {
  let returnMonths = [];
  const d = new Date(startDate);
  const addDays = (1 + 14 - d.getDay()) % 14 === 0 ? 14 : (1 + 14 - d.getDay()) % 14
  d.setDate(d.getDate() + addDays);
  let month = (d.getMonth() + 1);

  returnMonths.push(GET_MONTHS[month]);

  // console.log("[GET_MONTHS_DEFAULT_PLUS_2_MONDAY] returnMonths[0]: "+JSON.stringify(returnMonths[0]))

  // From Danny
  GET_MONTHS.forEach((m = {}, index) => {
    const monthItem = returnMonths[0] || {}
    const getMonthsItem = GET_MONTHS[0] || {}

    if (m.value !== monthItem.value && m.value !== getMonthsItem.value) {
      returnMonths.push(m);
    }
  })

  // console.log('[GET_MONTHS_DEFAULT_PLUS_2_MONDAY] ' + JSON.stringify(returnMonths));
  return returnMonths;
}

export const GET_MONTHS_DEFAULT_INTRO_PT = (startDate, numberSessionsPerWeek, introPt) => {
  let returnMonths = [];
  let introPtSessionCount = 0;
  let sessionCount = 0;

  if (introPt === 'six') {
    introPtSessionCount = 6;
  } else if (introPt === 'ten') {
    introPtSessionCount = 10;
  }

  if (numberSessionsPerWeek === '4wk') {
    sessionCount = 4;
  } else if (numberSessionsPerWeek === '3wk') {
    sessionCount = 3;
  } else if (numberSessionsPerWeek === '2wk') {
    sessionCount = 2;
  } else if (numberSessionsPerWeek === '1wk') {
    sessionCount = 1;
  } else if (numberSessionsPerWeek === '1fn') {
    sessionCount = 0.5;
  }

  const addWeeks = introPtSessionCount / sessionCount
  var d = new Date(startDate);
  d.setDate(d.getDate() + addWeeks * 7)
  let month = (d.getMonth() + 1);
  returnMonths.push(GET_MONTHS[month]);

  // console.log("[GET_MONTHS_DEFAULT_PLUS_2_MONDAY] returnMonths[0]: "+JSON.stringify(returnMonths[0]))  
  GET_MONTHS.forEach((m = {}, index) => {
    const monthItem = returnMonths[0] || {}
    const getMonthsItem = GET_MONTHS[0] || {}

    if (m.value !== monthItem.value && m.value !== getMonthsItem.value) {
      returnMonths.push(m);
    }
  })

  // console.log('[GET_MONTHS_DEFAULT_PLUS_2_MONDAY] ' + JSON.stringify(returnMonths));
  return returnMonths;
}

export const GET_MONTHS_DEFAULT_NOW = () => {
  let returnMonths = [];
  returnMonths.push(GET_MONTHS[THIS_MONTH]);

  // console.log("[GET_MONTHS_DEFAULT_NOW] returnMonths[0]: "+JSON.stringify(returnMonths[0]))

  GET_MONTHS.forEach((m = {}, index) => {
    const monthItem = returnMonths[0] || {}
    const getMonthsItem = GET_MONTHS[0] || {}

    if (m.value !== monthItem.value && m.value !== getMonthsItem.value) {
      returnMonths.push(m);
    }
  })

  // console.log('[GET_MONTHS_DEFAULT_NOW] ' + JSON.stringify(returnMonths));
  return returnMonths;
}

export const GET_DAYS_DEFAULT_NOW = () => {
  const d = new Date();
  const day = d.getDate();
  let __day__ = day < 10 ? '0' + day : day;

  let arr = [{ value: 'D|' + __day__, displayValue: day.toString() },];
  for (let i = 1; i <= 31; i++) {
    if (i !== day) {
      let j = i < 10 ? '0' + i : i;
      arr.push(
        { value: 'D|' + j, displayValue: i.toString() },
      );
    }
  }
  return arr;
}

export const DATE_DIFFERENCE_DAYS = (date1, date2) => {

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);

}


// (int) The current year
export const THIS_YEAR = +(new Date().getFullYear());

// (int) The current month starting from 1 - 12
// 1 => January, 12 => December
export const THIS_MONTH = +(new Date().getMonth()) + 1;

// Week days names and shortnames
export const WEEK_DAY_NAME = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
}

export const WEEK_DAY_NAME_SHORT_TO_LONG = {
  Sun: "Sunday",
  Mon: "Monday",
  Tues: "Tuesday",
  Wed: "Wednesday",
  Thurs: "Thursday",
  Fri: "Friday",
  Sat: "Saturday"
}

// Calendar months name
export const CALENDAR_MONTH_NAME = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
}


// Calendar months names and shortnames
export const CALENDAR_MONTHS = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec"
}

// Weeks displayed on calendar
export const CALENDAR_WEEKS = 6;

// Pads a string value with leading zeroes(0) until length is reached
// For example: zeroPad(5, 2) => "05"
export const zeroPad = (value, length) => {
  return `${value}`.padStart(length, '0');
}

// (int) Number days in a month for a given year from 28 - 31
export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;

  return month === 2
    ? leapYear
      ? 29
      : 28
    : months30.includes(month)
      ? 30
      : 31;
}

// (int) First day of the month for a given year from 1 - 7
// 1 => Sunday, 7 => Saturday
export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return +(new Date(`${year}-${zeroPad(month, 2)}-01`).getDay()) + 1;
}

// (bool) Checks if a value is a date - this is just a simple check
export const isDate = date => {
  const isDate = Object.prototype.toString.call(date) === '[object Date]';
  const isValidDate = date && !Number.isNaN(date.valueOf());

  return isDate && isValidDate;
}

// (bool) Checks if two date values are of the same month and year
export const isSameMonth = (date, basedate = new Date()) => {

  if (!(isDate(date) && isDate(basedate))) return false;

  const basedateMonth = +(basedate.getMonth()) + 1;
  const basedateYear = basedate.getFullYear();

  const dateMonth = +(date.getMonth()) + 1;
  const dateYear = date.getFullYear();

  return (+basedateMonth === +dateMonth) && (+basedateYear === +dateYear);

}

// (bool) Checks if two date values are the same day
export const isSameDay = (date, basedate = new Date()) => {

  if (!(isDate(date) && isDate(basedate))) return false;

  const basedateDate = basedate.getDate();
  const basedateMonth = +(basedate.getMonth()) + 1;
  const basedateYear = basedate.getFullYear();

  const dateDate = date.getDate();
  const dateMonth = +(date.getMonth()) + 1;
  const dateYear = date.getFullYear();

  return (+basedateDate === +dateDate) && (+basedateMonth === +dateMonth) && (+basedateYear === +dateYear);

}

// (string) Formats the given date as YYYY-MM-DD
// Months and Days are zero padded
export const getDateISO = (date = new Date()) => {

  if (!isDate(date)) return null;

  return [
    date.getFullYear(),
    zeroPad(+date.getMonth() + 1, 2),
    zeroPad(+date.getDate(), 2)
  ].join('-');

}

// ({month, year}) Gets the month and year before the given month and year
// For example: getPreviousMonth(1, 2000) => {month: 12, year: 1999}
// while: getPreviousMonth(12, 2000) => {month: 11, year: 2000}
export const getPreviousMonth = (month, year) => {
  const prevMonth = (month > 1) ? month - 1 : 12;
  const prevMonthYear = (month > 1) ? year : year - 1;

  return { month: prevMonth, year: prevMonthYear };
}

// ({month, year}) Gets the month and year after the given month and year
// For example: getNextMonth(1, 2000) => {month: 2, year: 2000}
// while: getNextMonth(12, 2000) => {month: 1, year: 2001}
export const getNextMonth = (month, year) => {
  const nextMonth = (month < 12) ? month + 1 : 1;
  const nextMonthYear = (month < 12) ? year : year + 1;

  return { month: nextMonth, year: nextMonthYear };
}

// Calendar builder for a month in the specified year
// Returns an array of the calendar dates.
// Each calendar date is represented as an array => [YYYY, MM, DD]

export default (month = THIS_MONTH, year = THIS_YEAR) => {

  // Get number of days in the month and the month's first day

  const monthDays = getMonthDays(month, year);
  const monthFirstDay = getMonthFirstDay(month, year);

  // Get number of days to be displayed from previous and next months
  // These ensure a total of 42 days (6 weeks) displayed on the calendar

  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth = (CALENDAR_WEEKS * 7) - (daysFromPrevMonth + monthDays);

  // Get the previous and next months and years

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

  // Get number of days in previous month
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

  // Builds dates to be displayed from previous month

  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  // Builds dates to be displayed from current month

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [year, zeroPad(month, 2), zeroPad(day, 2)];
  });

  // Builds dates to be displayed from next month

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });

  // Combines all dates from previous, current and next months
  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];

}